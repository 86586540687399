import React from 'react' 
import Banner from './Banner'
import Channel from './Channel'
export default function Home() {
  return (
    <>
    <Banner />
    <Channel/>
    </>
  )
}
